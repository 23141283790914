enum BillingRemittanceAccountReceivableSituationType {
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  NOT_SENT = 'NOT_SENT',
  PAID = 'PAID',
  PAID_WITHOUT_GENERATE_DOCUMENT = 'PAID_WITHOUT_GENERATE_DOCUMENT',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  SUCCESS = 'SUCCESS',
  SUCCESS_WITH_OCCURRENCES = 'SUCCESS_WITH_OCCURRENCES',
  SUCCESS_WITHOUT_BANK_REGISTRATION = 'SUCCESS_WITHOUT_BANK_REGISTRATION',
  IN_REMITTANCE = 'IN_REMITTANCE',
  UNDEFINED = 'UNDEFINED',
}

export default BillingRemittanceAccountReceivableSituationType;
